import React from "react"
import Layout from "../components/layout"

const ResumePage = () => (
    <Layout>
        <h1>Resume</h1>
        <p>Coming Soon</p>

    </Layout>
)

export default ResumePage;